header.nav-wrap {
    background: $primaryBlue;
    img.logo {
        width: 300px;
    }
    .nav-link {
        color: $cream;
        font-size: 17px;
        transition: 0.2s all;
        &:hover {
            color: $primaryGold;
        }
    }
}

.navbar {
    display: flex;
    justify-content: center;
}

@media (min-width: 768px) {
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
    }
    
}