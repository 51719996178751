$primaryBlue: #333d4f;
$primaryGold: #d4ab45;
$secondaryBlue: #cbd0d7;
$cream: #f4f3eb;

//@import url('https://fonts.googleapis.com/css?family=Poppins:400,600,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Noto+Serif+TC:400,700&display=swap');

@font-face {
  font-family: "Proxima Nova Light";
  src: url("../fonts/proxima-nova/proxima-nova-light.ttf") format("ttf"),
       url("../fonts/proxima-nova/proxima-nova-light.eot") format("eot"),
       url("../fonts/proxima-nova/proxima-nova-light.woff") format("woff"),
       url("../fonts/proxima-nova/proxima-nova-light.svg") format("svg");
}

@font-face {
  font-family: "Proxima Nova Bold";
  src: url("../fonts/proxima-nova/proxima-nova-bold.ttf") format("ttf"),
       url("../fonts/proxima-nova/proxima-nova-bold.eot") format("eot"),
       url("../fonts/proxima-nova/proxima-nova-bold.woff") format("woff"),
       url("../fonts/proxima-nova/proxima-nova-bold.svg") format("svg");
}

@font-face {
  font-family: "Proxima Nova Extrabold";
  src: url("../fonts/proxima-nova/proxima-nova-extrabold.ttf") format("ttf"),
       url("../fonts/proxima-nova/proxima-nova-extrabold.eot") format("eot"),
       url("../fonts/proxima-nova/proxima-nova-extrabold.woff") format("woff"),
       url("../fonts/proxima-nova/proxima-nova-extrabold.svg") format("svg");
}

body {
    font-family: 'Proxima Nova Light', sans-serif;
    color: $primaryBlue;
    font-size: 16px;
    overflow-x: hidden;
}


//@media
//only screen and (-webkit-min-device-pixel-ratio : 2),
//only screen and (min-device-pixel-ratio : 2) {
//    body { font-size: 105%; }
//}

h1, h2, h3, h4, h5, h6, h7 {
    font-family: 'Noto Serif TC', serif;
    line-height: 1.35em;
}

.bold { 
    font-family: 'Proxima Nova Bold', sans-serif;
}

strong {
    font-family: 'Proxima Nova Extrabold', sans-serif;
}

.desktop-lg {display: block;}
.desktop {display: block;}
.tablet {display: none;}
.mobile {display: none;}

.full {
    float: left;
    width: 100%;
}
@mixin default-btn {
    background: $primaryBlue;
    border: 2px solid $primaryBlue;
    border-radius: 0;
    padding: 3px 12px;
    display: inline-block;
    color: $cream;
    transition: 0.2s all;
    &:hover {
        color: $primaryBlue;
        text-decoration: none;
        background: none;;
    }
}


@mixin dark-gradient {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1e5799+0,7db9e8+100 */
        background: $primaryBlue; /* Old browsers */
        background: -moz-linear-gradient(-45deg, $secondaryBlue 0%, $primaryBlue 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg, $secondaryBlue 0%,$primaryBlue 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg, $secondaryBlue 0%,$primaryBlue 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$mediumBgDarkGradient', endColorstr='$mediumBg',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

@media (max-width: 992px) {
    .desktop-lg {display: none;}
    .desktop {display: block;}
    .tablet {display: block;}
    .mobile {display: block;}
    
    .global-sm {display: none;}
}
@media (max-width: 767px) {
    .desktop-lg {display: none;}
    .desktop {display: none;}
    .mobile {display: block;}
    .row {
        margin-left: 0;
        margin-right: 0;
    }
}

.upper-line {
    width: 70px;
    height: 5px;
    display: block;
    margin: 12px 0 20px 0;
    &.primaryBlue {
        background: $primaryBlue;
    }
    &.primaryGold {
        background: $primaryGold;
    }
    &.centered {
        margin: 12px auto 20px auto;
    }
}

.section-padding {
    padding: 45px 0;
}

.centered {
    text-align: center;
}