.home-hero {
    background: url(../img/home-hero.jpg) center;
    -webkit-background-size: 100%; 
    -moz-background-size: 100%; 
    -o-background-size: 100%; 
    background-size: 100%; 
    -webkit-background-size: cover; 
    -moz-background-size: cover; 
    -o-background-size: cover; 
    background-size: cover;
    background-attachment: fixed;
    padding: 100px 0;
    h1 {
        color: $primaryBlue;
//        font-weight: 700;
    }
}


.intro-outter {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    .intro-wrap {
        display: inline-block;
        width: 870px;
    }
}
//.home-intro {
//    .image-box {
//        height: 450px;
//        width: 550px;
//        display: block;
//        background: url(../img/home-intro.jpg) center;
//        float: left;
//    }
//    .text-box {
//        background: $cream;
//        padding: 30px;
//        margin-top: -405px;
//        margin-bottom: -405px;
//        float: right;
//        width: 410px;
//        position: relative;
//        right: 15px;
//        h4 {
//            font-size: 18px;
//        }
//        .intro-btn {
//            @include default-btn;
//            font-size: 18px;
//            margin-top: 10px;
//        }
//    }
//    .text-box-bg {
//        background: $secondaryBlue;
//        padding: 30px;
//        margin-top: -385px;
//        margin-bottom: -385px;
//        float: right;
//        width: 410px;
//        height: 340px;
//        right: 0px;
//    }
//}

.home-about {
    p {
//        font-size: 17px;
//        font-family: 'Noto Serif TC', serif;
//        line-height: 1.35em;
    }
    .intro-btn {
        @include default-btn;
        font-size: 18px;
        margin-top: 10px;
    }
}


.home-info {
    background: $primaryBlue;
    h3, p { 
        color: $cream;
        text-align: center;
    }
    .reason {
        padding: 0 20px;
    }
}


.home-services {
    background: url(../img/home-services.jpg) center;
    -webkit-background-size: 100%; 
    -moz-background-size: 100%; 
    -o-background-size: 100%; 
    background-size: 100%; 
    -webkit-background-size: cover; 
    -moz-background-size: cover; 
    -o-background-size: cover; 
    background-size: cover;
    background-attachment: fixed;
    padding: 100px 0;
    h4 {
        color: $cream;
        text-align: center;
        font-size: 18px;
    }
    .services-btn {
        @include default-btn;
        background: $primaryGold;
        border: 2px solid $primaryGold;
        width: 220px;
        text-align: center;
        display: block;
        margin: 0 auto;
        font-size: 18px;
        margin-top: 20px;
        &:hover {
            color: $primaryGold;
        }
    }
}

@media (max-width: 767px) {
    .home-hero {
        padding: 50px 0;    
        h1 {
            color: #333d4f;
            font-size: 25px;
        }
    }
    .home-about {
        .upper-line {
            display: none;
        }
        img {
            margin-bottom: 20px;
        }
    }
    .home-info {
        .reason {
            margin-bottom: 50px;
        }
        h3 {
            font-size: 21px;
        }
    }
}