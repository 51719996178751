.footer-contact {
    background: $cream;
    text-align: center;
    a {
        color: $primaryBlue;
    }
    .col-md-4 {
        border-right: 1px solid $primaryBlue;
    }
    .col-md-4:last-child {
        border-right: none;
    }
}

.sub-footer {
    padding: 15px 0;
    p {
        margin: 0;
        text-align: center;
        color: $primaryBlue;
        a {
            color: $primaryBlue;
            text-decoration: underline;
            transition: 0.2s all;
            &:hover {
                color: $primaryGold;
            }
        }
    }
}